import { Modal, Form, Input, message, Row, Col } from 'antd';

import React, { useEffect, useState } from 'react';

import { REQUEST } from '../../../utils/constants';
import { useFetch } from '../../../hooks/usefetch';
import { UserForm } from '../../../types/Form';
import { Text, TitleSlab, Value } from '../../../components/Typography';
import { FulfillWishButton } from '../../../components/Button';

const displayErrorMessage = () => {
  message.error(
    'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
  );
};

type ReservedWish = {
  name: string;
  nummer: number;
  gezogen_timestamp: number;
};

interface UserFormModalProps {
  clearWish: () => void;
  reservedWish: ReservedWish;

  displayUserForm: boolean;
  setDisplayUserForm: (value: React.SetStateAction<boolean>) => void;
}

const UserFormModal: React.FC<UserFormModalProps> = (props) => {
  const { error: unblockError, refetch: unblockWish } = useFetch(
    REQUEST.ENDPOINTS.UNBLOCK,
    'POST',
  );

  const {
    loading,
    error: fulfillError,
    refetch: fulfillWish,
    status: fulfillStatus,
  } = useFetch(REQUEST.ENDPOINTS.SATISFY, 'POST');

  const [form] = Form.useForm<UserForm>();

  const [success, setSuccess] = useState<boolean>(false);

  const handleCancel = () => {
    if (!success) {
      unblockWish({
        nummer: props.reservedWish.nummer,
        gezogen_timestamp: props.reservedWish.gezogen_timestamp,
      });
    }
    props.setDisplayUserForm(false);
    props.clearWish();
  };

  const onFinish = async (values: UserForm) => {
    fulfillWish({
      nummer: props.reservedWish.nummer,
      gezogen_timestamp: props.reservedWish.gezogen_timestamp,
      name: values.name,
      vorname: values.vorname,
      email: values.email,
    });
  };

  const { setDisplayUserForm } = props;

  useEffect(() => {
    if (fulfillError || unblockError) {
      setDisplayUserForm(false);
      displayErrorMessage();
    }
  }, [fulfillError, unblockError, setDisplayUserForm]);

  useEffect(() => {
    if (fulfillStatus === 200) {
      setSuccess(true);
    }
  }, [fulfillStatus, setSuccess]);

  const isButtonDisabled = () => {
    const fields = Object.keys(form.getFieldsValue());

    if (fields.filter((field) => !form.isFieldTouched(field)).length === 0) {
      if (
        form.getFieldsError().filter(({ errors }) => errors.length).length === 0
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <Modal
      centered
      forceRender
      footer={null}
      onCancel={handleCancel}
      maskClosable={!success}
      visible={props.displayUserForm}
    >
      {success ? (
        <>
          <TitleSlab level={4}>
            Danke für deine Teilnahme am Weihnachtstag der HSV-Stiftung!
          </TitleSlab>
          <Text>
            Du erhältst in Kürze eine Bestätigungsmail mit den Informationen zu
            deinem ausgesuchten Wunsch und weiteren Informationen zur Übergabe
            der Geschenke.
          </Text>
        </>
      ) : (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <TitleSlab level={3}>Erfüllung von Wunsch:</TitleSlab>

          <Text>Wunschnummer:</Text>
          <Value>{props.reservedWish.nummer}</Value>

          <Text>Name des Kindes:</Text>
          <Value>{props.reservedWish.name}</Value>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Vorname"
                name="vorname"
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie Ihren Vornamen ein.',
                  },
                ]}
              >
                <Input placeholder="Vorname" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Bitte geben Sie Ihren Namen ein.',
                  },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="E-Mail"
            name="email"
            rules={[
              {
                type: 'email',
                required: true,
                message: 'Bitte geben Sie eine gültige Adresse ein.',
              },
            ]}
          >
            <Input placeholder="E-Mail" />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() =>
              loading ? (
                <FulfillWishButton block loading type="default">
                  Lade
                </FulfillWishButton>
              ) : (
                <FulfillWishButton
                  block
                  type="default"
                  htmlType="submit"
                  disabled={isButtonDisabled()}
                >
                  Wunscherfüllung bestätigen
                </FulfillWishButton>
              )
            }
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};

export default UserFormModal;
